@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

body {
  overflow-x: hidden;
}

._nodrag,
img,
svg {
  -webkit-user-drag: none;
  user-drag: none;
}

/* width */
.scrollbar-thin::-webkit-scrollbar {
  width: 10px;
  height: 16px;
}

/* Track */
.scrollbar-thin::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

/* Handle */
.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #dbe5e0;
  border-radius: 100vh;
  border: 2px solid #edf2f7;
  cursor: grab;
}

/* Handle on hover */
.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background: #cad4d0;
  cursor: grab;
}

.input-field {
  @apply transition-all duration-200 ease-in-out rounded outline-none border border-gray-200 focus-visible:ring-2 active:ring-2 focus:ring-2 active:ring-offset-2 focus-visible:ring-offset-1 focus:ring-offset-1 focus-visible:ring-blue-200 focus:ring-blue-200 active:ring-blue-200 disabled:opacity-80 disabled:text-gray-700 disabled:cursor-not-allowed outline-transparent ring-transparent ring-offset-transparent focus:ring-offset-white focus-visible:ring-offset-white active:ring-offset-white ring-offset-0 ring disabled:bg-neutral-200;
}
